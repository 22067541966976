@import url("https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@400;500;600;800&display=swap");

/* @import url(https://db.onlinewebfonts.com/c/23c0fcab84d99da0de762de7e220a6e1?family=Europa+Regular); */

:root {
  /* _____________________*COLORS*____________________ */
  --primary-white: #efedef;
  --primary-light-grey: #c3bcc3;
  --primary-grey: #655f67;
  --primary-dark-grey: #666666;
  --primary-dark: #0f0913;
  --primary-golden: #ae832d;
  --primary-hover-red: #f33501;

  /* _____________________*FONTS*____________________ */

  --font-jetbrains: "JetBrains Mono", monospace;
  --font-jetbrains-regular: "JetBrains Mono", monospace;
  --font-jetbrains-medium: "JetBrains Mono", monospace;
  --font-jetbrains-bold: "JetBrains Mono", monospace;
  --font-jetbrains-extrabold: "JetBrains Mono", monospace;
}

body {
  background-color: var(--primary-white) !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: "JetBrains Mono", monospace;
}

/* _____________________*SCROLLBAR STYLES*____________________ */

/* width */
::-webkit-scrollbar {
  width: 0.625rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--primary-grey);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary-dark);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #046f9d;
}

/* _____________________*PORTFOLIO STYLES*____________________ */

/* _____________________PROJECTS____________________ */

.projects {
  position: relative;
  text-align: center;
  padding-bottom: 2rem;
  background: var(--primary-light-grey);
  z-index: 2;
  overflow: hidden;
}

.projects h1 {
  color: var(--primary-dark);
  text-transform: uppercase;
}

.projects .circle {
  position: relative;
  margin: 0.375rem auto;
  background: var(--primary-white);
  border-radius: 50%;
  width: 3.125rem;
  height: 3.125rem;
}

.projects .icon {
  font-size: 1.4rem;
  color: var(--primary-white);
  position: absolute;
  top: 8%;
  left: 50%;
  transform: translate(-50%, 50%);
}

.projects .box {
  height: 16rem;
  border-bottom: 0.3125rem solid var(--primary-white);
  padding: 0.625rem;
  margin-bottom: 1.875rem;
  background: var(--primary-white);
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.projects .box:hover {
  background: var(--primary-white);
  border-bottom: 0.3125rem solid var(--primary-dark);
}

.projects .box:hover .icon {
  color: var(--primary-dark);
}

.projects .box:hover .circle {
  background: var(--primary-white);
}

.projects .box:hover p {
  color: var(--primary-white);
}
/* _____________________ PROJECT CARD STYLES ____________________ 
.project-card {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  width: 300px;
}

.project-card:hover {
  transform: scale(1.05);
}

.project-image {
  background-color: #f2f2f2;
  padding: 20px;
  text-align: center;
}

.project-details {
  padding: 20px;
}

.project-details h3 {
  margin-bottom: 10px;
}

.project-details p {
  color: #555555;
  font-size: 14px;
  margin-bottom: 20px;
}

.read-more-btn {
  background-color: var(--primary-golden);
  border: none;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  font-size: 14px;
  padding: 10px;
  transition: background-color 0.3s ease-in-out;
}

.read-more-btn:hover {
  background-color: var(--primary-golden);
}

/* _____________________OVERFLOW____________________ */
/* 
.portfolio-content {
  border: solid #000000;
  border-radius: 0.9375rem;
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  padding: 2rem;
  top: 0;
  left: -105%;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  transition: all 1s;
}

.card-title {
  margin-bottom: 1rem !important;
}

.card-title,
.portfolio-description {
  color: #fff;
  font-family: var(--font-jetbrains);
  font-weight: 800;
}

.portfolio-content .read-more {
  font-family: var(--font-jetbrains);
  font-weight: 200;
  text-decoration: underline;
  cursor: pointer;
  color: var(--primary-white);
  background-color: transparent;
  border: none;
  padding: 0;
  margin-left: 0.1875rem;
}

.portfolio-content .read-more:hover {
  font-weight: 800;
} */

/* _____________________HOVER OVERFLOW FUNCTIONALLITY____________________ */

/* @media (hover: hover) {
 
  .portfolio-card:hover .portfolio-content,
  .portfolio-card:active .portfolio-content {
    left: 0;
  }
} */

/* _____________________MOBILE DIVICES OVERFLOW FUNCTIONALLITY____________________ */

/* @media (hover: none) { */
/* target only devices that can´t hover */
/* .portfolio-card .card-img-top {
    box-shadow: none;
  }
   .portfolio-content {
    background: rgba(0, 0, 0, 0.4);
    border-color: transparent;
    border-radius: 0 0 0.9375rem 0.9375rem;
    padding: 0.625rem;
    width: 95%;
    height: auto;
    right: 0;
    bottom: 0.5rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -0%);
  }

  .card-title {
    font-size: 1rem;
    margin-bottom: 0.5rem !important;
  }

  .portfolio-description {
    font-size: 0.75rem;
  }

  .read-more {
    font-size: 0.75rem;
  }
} */

/* _____________________PROJECTS____________________ */

/* .projects {
  position: relative;
  text-align: center;
  padding-bottom: 2rem;
  background: var(--primary-light-grey);
  z-index: 2;
}

.projects h1 {
  color: var(--primary-dark);
  text-transform: uppercase;
}

.popup-inner .circle {
  position: relative;
  margin: 0.375rem auto;
  background: var(--primary-white);
  border-radius: 50%;
  width: 3.125rem;
  height: 3.125rem;
}

.popup-inner .icon {
  font-size: 1.4rem;
  color: var(--primary-white);
  position: absolute;
  top: 8%;
  left: 50%;
  transform: translate(-50%, 50%);
}

/* .projects .box {
  height: 16rem;
  border-bottom: 0.3125rem solid var(--primary-white);
  padding: 0.625rem;
  margin-bottom: 1.875rem;
  background: var(--primary-white);
  transition: 0.3s ease-in-out;
  cursor: pointer;
} */

/* .projects .box:hover {
  background: var(--primary-white);
  border-bottom: 0.3125rem solid var(--primary-dark);
} */

/* .popup-inner .circle:hover .icon {
  color: var(--primary-dark);
}

.popup-inner .circle:hover a {
  color: var(--primary-white);
} */

/*------------ MEDIA ------------------*/
/* @media (max-width: 768px) { */
/* popup image */
/* .portfolio-image-popupbox {
    width: 100%;
  }
  .popup {
    height: 100%;
  }
}

@media (max-height: 570px) {
  .popupbox-wrapper {
    height: 100%;
  }
  .portfolio-image-popupbox {
    width: 50%;
  }
}

@media (max-height: 280px) {
  .popupbox-wrapper {
    height: 90%;
  }
  .portfolio-image-popupbox {
    width: 30%;
  }
} */

/* _____________________ CONTACT FORM ____________________ */

.contact-form {
  display: flex;
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-form .greeting-message {
  font-size: 2.5rem;
  font-family: var(--font-jetbrains);
}

nav .nav-home {
  font-size: 1.5rem;
  text-align: center;
  transition: 1s;
  color: var(--primary-light-grey);
}

nav .nav-home:hover {
  font-size: 2rem;
  transition: 0.5s;
  color: var(--primary-white);
}
/* _____________________ CONTACT FORM INPUTS ____________________ */

.contact-form input,
.contact-form textarea {
  width: 100%;
  box-sizing: border-box;
  background: none;
  resize: none;
  border: 0.125rem solid var(--primary-light-grey);
  color: var(--primary-white);
  outline: 0 !important;
  box-shadow: 0 !important;
  top: 0;
  font-size: 1.2rem;
  font-family: var(--font-jetbrains) !important;
}

.contact-form label {
  font-size: 1.2rem;
  font-family: var(--font-jetbrains) !important;
  color: var(--primary-dark-grey);
}

.contact-form input:focus,
.contact-form textarea:focus {
  background: transparent;
  color: var(--primary-white);
  border-bottom: 0.125rem solid var(--primary-dark-grey);
}

.contact-form textarea {
  height: 12.2rem;
  margin-bottom: 0;
}

.contact-form .message-section {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  align-content: center;
}

.contact-form .btn-main-offer {
  border: 0.0625rem solid var(--primary-white);
  color: var(--primary-white);
  border-radius: 3.125rem;
  padding: 0.625rem 0.75rem 0.75rem 0.75rem;
  width: 40%;
  text-decoration: none;
  background-color: transparent;
  opacity: 0.8;
  font-size: 1.2rem;
  font-family: var(--font-jetbrains) !important;
  margin-top: 0.9rem;
}

.contact-form .btn-main-offer:hover {
  background-color: var(--primary-white);
  color: var(--primary-light-grey);
  transition: 0.2s ease-in-out;
}

.error-message {
  color: var(--primary-grey);
  text-align: left;
  font-family: var(--font-jetbrains);
  font-size: 1rem;
}

.success-message {
  font-size: 1.5rem;
  color: var(--primary-white);
  font-family: var(--font-jetbrains);
  margin-bottom: 1.5rem;
}

/* _____________________ CONTACT FORM MEDIA QUERY ____________________ */

@media (max-width: 768px) {
  .contact-form input,
  .contact-form textarea {
    font-size: 1rem;
  }
  .contact-form .greeting-message {
    font-size: 1.5rem;
  }
}

/* _____________________ FOOTER ____________________ */

.footer {
  position: relative;
  overflow: hidden;
  z-index: 1;
  background-color: var(--primary-white);
}

.intro-footer {
  position: absolute;
  display: flex;
  top: 23%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--primary-light-grey);
  background-color: var(--primary-dark);
  border-radius: 0.75rem;
  box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px #141c3a;
  z-index: 1;
}

.footer-contact-btn {
  background-color: transparent;
  border: 0.125rem solid var(--primary-white);
  border-radius: 1.5625rem;
  font-size: 1.5rem;
  padding: 0.5rem 3rem;
  margin: 3rem 3.125rem;
  font-family: var(--font-jetbrains);
  text-decoration: none;
  color: var(--primary-light-grey);
}

.footer-contact-btn:hover {
  background-color: var(--primary-white);
  color: var(--primary-dark);
}

.lets-connect {
  margin: 3rem 3rem 0 0;
}

.chat-invite {
  margin: 3rem 3rem 0 0;
}

.footer-position {
  background-color: var(--primary-white);
  height: 20vh;
}

div .footer-logo {
  margin-top: 5rem;
  margin-bottom: 1rem;
  padding-top: 3rem;
}

.footer-logo-img {
  max-width: 6.5rem;
  height: auto;
}

.back-header img {
  width: 20%;
  height: auto;
  margin-bottom: 2rem;
}

.motto {
  font-family: var(--font-jetbrains);
  font-size: 1.2rem;
  color: var(--primary-light-grey);
}

.social-link a {
  text-decoration: none;
  color: var(--primary-light-grey);
  cursor: pointer;
  transition: 1s;
  margin: 0.9rem;
  border-radius: 50px;
  padding: 1.5rem 0.9rem 0.9rem 0.9rem;
  border: 2px solid var(--primary-light-grey);
  border-radius: 0.75rem;
  box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px #141c3a;
}

.social-link a:hover {
  color: var(--primary-dark);
  font-size: 1.1rem;
  transition: 0.5s;
  border: 2px solid var(--primary-dark);
}

.github a {
  color: var(--primary-light-grey);
  text-decoration: none;
  cursor: pointer;
  transition: 1s;
  margin: 0.9rem;
  border-radius: 50px;
  padding: 1.5rem 0.9rem 0.9rem 0.9rem;
  border: 2px solid var(--primary-light-grey);
  border-radius: 0.75rem;
  box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px #141c3a;
}

.github a:hover {
  color: var(--primary-dark);
  font-size: 1.1rem;
  transition: 0.5s;
  border: 2px solid var(--primary-dark);
}
.handcrafted,
.made-with {
  color: var(--primary-light-grey);
  font-family: var(--font-jetbrains);
}

.handcrafted {
  margin-top: 2rem;
  font-size: 1.2rem;
}

.my-name {
  font-family: var(--font-jetbrains);
  font-weight: bold;
}

.made-with {
  font-size: 0.9rem;
  margin: 2rem auto;
  margin-left: auto;
  margin-right: auto;
}

.boostrap-logo,
.react-logo {
  padding: 0 0 0 0.9rem;
}

/* _____________________ FOOTER MEDIA QUERY ____________________ */

@media (max-width: 1180px) {
  .intro-footer {
    flex-direction: column;
  }

  .back-header img {
    width: 50%;
  }

  .lets-connect {
    font-size: 1.2rem;
    margin: 0.2rem 0 0 0;
    padding: 0rem;
  }
  .chat-invite {
    font-size: 0.9rem;
    margin: 0.9rem 0 1rem 0;
    padding: 0rem;
  }
  .footer-contact-btn {
    font-size: 1rem;
    margin: 0 0 0.8rem 0 !important;
  }
}
