/* _____________________NAVBAR STYLES____________________ */
nav {
  z-index: 1;
}

.nav-main {
  padding: 1px !important;
}

.logo {
  width: 6.25rem !important;
}

/* _____________________MENU LINKS____________________ */
.navbar-custom {
  background-color: transparent;
}

.nav-contact {
  font-size: 1.5rem;
  letter-spacing: 0.0375rem;
  cursor: pointer;
}

.navbar-nav > li > a {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}
.nav-experience {
  color: var(--primary-dark) !important;
  font-family: var(--font-jetbrains);
  font-size: 1.125rem !important;
  padding: 0.5rem 2rem !important;
  background-color: transparent;
}

.nav-experience:hover {
  color: var(--primary-golden) !important;
}

.nav-contact {
  color: var(--primary-dark) !important;
  border: 0.1875rem solid var(--primary-dark);
  font-family: var(--font-jetbrains);
  border-radius: 1.5625rem;
  font-size: 1.125rem !important;
  padding: 0.5rem 2rem !important;
  background-color: transparent;
}

.nav-contact:hover {
  color: var(--primary-white) !important;
  background-color: var(--primary-golden);
  border: 0.1875rem solid var(--primary-golden);
}

.navbar-brand {
  max-width: 17.25rem;
  padding: 0 !important;
  height: 6rem !important;
}

.navbar-toggler {
  background-color: var(--primary-golden);
}

/* _____________________MEDIA QUERY MENU LINKS____________________ */

@media (max-width: 768px) {
  .navbar-brand {
    width: 7rem;
  }

  .logo {
    margin-top: 0.2rem;
    position: absolute;
    top: 0.375rem;
    width: 5.5119rem !important;
    height: auto;
    object-fit: cover;
  }

  .nav-experience {
    border-top: 0.1875rem solid var(--primary-dark-grey);
    text-align: center;
    margin-top: 2rem !important;
  }
  .nav-contact {
    margin-bottom: 1rem;
    text-align: center;
  }
}
