/* _____________________HEADER STYLES____________________ */
.header-wrapper {
  position: relative;
  background-color: var(--primary-white);
  height: 100vh;
  overflow: hidden;
}

.main-info {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 1;
}

.main-info h1 {
  color: var(--primary-dark);
  font-family: var(--font-jetbrains);
  font-weight: 800;
}

/* _____________________MEDIA QUERY HEADER STYLES____________________ */
@media (max-width: 991px) {
  .header-wrapper {
    height: 90vh;
  }
}

/* _____________________TYPED TEXT____________________ */
.typed-text {
  font-size: 2.5rem;
  color: var(--primary-dark);
  font-family: var(--font-jetbrains);
  font-weight: 400;
}

@media (max-width: 768px) {
  .main-info h1 {
    font-size: 1.5rem !important;
  }
  .typed-text {
    font-size: 1.3rem !important;
  }
}

/* _____________________LEARN MORE ____________________ */

.learnmore {
  color: var(--primary-dark);
  /* padding: 0.625rem 0.75rem 0.75rem 0.75rem; */
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin-bottom: 1.5rem;
}

.learnmoretext {
  font-size: 1.2rem;
}

.learnmorearrow {
  font-size: 1.5rem;
  color: var(--primary-dark);
  margin-bottom: 2rem;
  transition: 1s;
}

.learnmorearrow:hover {
  font-size: 2rem;
  color: var(--primary-golden);
  transition: 0.5s;
}

/* _____________________PARTICLES____________________ */
#tsparticles {
  position: absolute;
  height: 99vh;
  width: 100%;

  z-index: 1;
}

@media (max-width: 768px) {
  .particles-canvas {
    display: none;
  }
}
