/* _____________________POPUP BOX____________________ */

.popup {
  z-index: 2;
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
}

.popup-inner {
  position: relative;
  padding: 2.5rem;
  width: 100%;
  max-width: 40rem;
  color: var(--primary-light-grey);
  background-color: var(--primary-dark);
}
.popup-repoName {
  font-family: var(--font-jetbrains);
  color: var(--primary-golden);
  text-align: center;
  font-size: 1.4rem;
}

.popup-repoDescription {
  font-family: var(--font-jetbrains);
  font-weight: 400;
  text-align: justify;
  font-size: 1rem;
}
.popup-repoBuiltWith {
  font-family: var(--font-jetbrains);
  color: var(--primary-golden);
  text-align: left;
  font-size: 1rem;
}

.githubRepoLink {
  width: 50%;
  float: left;
}

.deploymentlink {
  width: 50%;
  float: right;
}

.githubRepoLink .read-more,
.deploymentlink .read-more {
  font-size: 1rem;
  font-family: var(--font-jetbrains);
  cursor: pointer;
  color: var(--primary-white);
  background-color: transparent;
  text-decoration: none;
  margin-left: 2rem;
  position: absolute;
  bottom: 2%;
  transition: 0.7s ease-in-out;
}
.githubRepoLink .read-more:hover,
.deploymentlink .read-more:hover {
  font-size: 1.1rem;
  transition: 0.5s ease-out;
  color: var(--primary-golden);
}

.githubRepoLink .icon,
.deploymentlink .icon {
  font-size: 1.5rem;
  position: absolute;
  bottom: 2%;
}

.popup-inner .return-main {
  position: absolute;
  top: 0.5625rem;
  right: 0.75rem;
  cursor: pointer;
  font-size: 0.8rem;
  border: 0;
  transition: 0.2s;
}

.popup-inner .return-main:hover {
  font-size: 0.9rem;
  transition: 0.5s;
}

.popup-inner .popup-img {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.3rem solid var(--primary-golden);
  border-radius: 0.9375rem;
  margin-bottom: 0.8rem;
  width: 100%;
  height: auto;
  transition: 2s;
}

/*------------ POPUP MEDIA QUERY ------------------*/
@media (max-width: 48rem) {
  /* popup inner links */

  /* ajust font size */
  .popup-repoDescription {
    text-align: left;
    font-size: 0.8rem;
  }
  .githubRepoLink {
    width: 100%;
    float: none;
    margin-bottom: 1rem;
    position: relative;
    bottom: 2%;
  }

  .deploymentlink {
    width: 100%;
    float: none;
    position: relative;
    bottom: 2%;
  }

  .githubRepoLink .icon,
  .deploymentlink .icon {
    font-size: 1.5rem;
    position: relative;
    bottom: 2%;
  }
}
