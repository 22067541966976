/* _____________________ABOUT ME____________________ */
.aboutme-wrapper {
  position: relative;
  z-index: 2;
  background-color: var(--primary-white);
}
.image-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.aboutme-img {
  width: 17rem;
  margin-top: 0.5rem;
  margin-left: 4rem;
}

.about-heading {
  color: var(--primary-dark);
  font-family: var(--font-jetbrains);
}

.aboutme-description {
  font-family: var(--font-jetbrains);
  font-size: 1.2rem;
}

.engineering-experience {
  color: var(--primary-golden);
  font-weight: 800;
}

.programmer-education {
  color: var(--primary-golden);
  font-weight: 800;
}

.aboutme-container {
  overflow: hidden;
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeIn {
  animation: fadeInAnimation 4s ease forwards;
}

/* _____________________ABOUT ME MEDIA QUERY____________________ */

@media (max-width: 991px) {
  .aboutme-container {
    max-width: 95%;
  }
  .aboutme-img {
    width: 13rem;
    margin-left: 0;
    margin-bottom: 2rem;
  }
  .about-me h1 {
    text-align: center;
  }
  .aboutme-description {
    text-align: justify;
    align-items: center;
    font-size: 0.9rem;
  }
}
