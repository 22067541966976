.carousel {
  z-index: 2;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  width: 23rem;
  height: auto;
  perspective: 500px;
  transform-style: preserve-3d;
}

.card-container {
  z-index: 2;
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotateY(calc(var(--offset) * 50deg))
    scaleY(calc(1 + var(--abs-offset) * -0.4))
    translateZ(calc(var(--abs-offset) * -30rem))
    translateX(calc(var(--direction) * -5rem));
  filter: blur(calc(var(--abs-offset) * 1rem));
  transition: all 0.3s ease-out;
}

.nav {
  color: var(--primary-dark);
  font-size: 5rem;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  z-index: 2;
  cursor: pointer;
  user-select: none;
  background: unset;
  border: unset;
}
.nav.left {
  transform: translateX(-100%) translatey(-50%);
}

.nav.right {
  right: 0;
  transform: translateX(100%) translatey(-50%);
}

/*------------ MEDIA QUERY ------------------*/
@media (max-width: 48rem) {
  .nav.left {
    z-index: 2;
    transform: translateX(1%) translatey(-50%);
  }

  .nav.right {
    z-index: 2;
    right: 0;
    transform: translateX(1%) translatey(-50%);
  }
}
